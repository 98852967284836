import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { connect } from 'react-redux'

// import modelMap from '../../model/map'

import MapMapbox from './MapMapbox'
import MapHeader from '../MapHeader';
import Legend from "../legends";
import Timeline from '../Timeline/TimeLine';
import InformationStrip from '../InformationStrip/InformationStrip';
// import FeatureInfoLayer from "../FeatureInfoLayer";
import PopupFeatureInfo from '../FeatureInfo/Sidebar'

const Map = ({ focus, style, states, layout, forecastLayer }) => {
  const [displaySearchBar, setDisplaySearchBar] = useState(false);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [forecastInterval, setForecastInterval] = useState(0);
  
  const onSelect = (selected) => { setSelectedFeatures(selected) };

  const intervalChanged = (interval) => {setForecastInterval(interval)};

  return (
    <>
      <InformationStrip currentInterval={forecastInterval} onChangeInterval={intervalChanged} />
      <button
        type="button"
        className="btn searchbar-button"
        onClick={() => {
          setDisplaySearchBar(!displaySearchBar);
        }}
      >
        Rechercher
      </button>
      {displaySearchBar && <MapHeader />}
      <MapMapbox
        focus={focus}
        style={style}
        layout={layout}
        states={states}
        forecastLayer={forecastLayer}
        forecastInterval={forecastInterval}
        onSelect={onSelect}
      />
      <PopupFeatureInfo content={selectedFeatures} layers={layout}/>
      <Legend key="Legend" />
      {/* <FeatureInfoLayer /> */}
      <Timeline />
    </>
  );
};

Map.propTypes = {
  focus: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  // map: PropTypes.object,
  style: PropTypes.object,
  states: PropTypes.object,
  layout: PropTypes.array,
  forecastLayer: PropTypes.string, 
  forecastInterval: PropTypes.number
  // rebuildMap: PropTypes.number
};

export default Map;
