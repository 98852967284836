import React, { useContext, useRef, useEffect, useState } from 'react';
import { ForecastContext } from '../Forecasts';
import ForecastSelector from './ForecastSelector';
import ForecastSourceDisplay from './ForecastSourceDisplay';
import $ from "jquery";
import "bootstrap-slider";
import moment from "moment";

// confugurable to show the reference station
// chose between a select right in the component or conf page
// chose prevision depending on the latest bulletin

/**
 * This component is used to display the information strip at the top of the map page.
 * It includes:
 * - the current displayed forecast bulletin and its alternative
 * - the possibility to select another forecast date and/or alternative
 * - the possibility to select the forecast values to display
 *
 * @returns {React.Component} The information strip in top of the map
 */
const InformationStrip = ({currentInterval, onChangeInterval}) => {
  const { currentForecast } = useContext(ForecastContext)
  const slider = useRef();
  const playTo = useRef(null);
  const [forecastInterval, setForecastInterval] = useState(0)
  const [forecastPlaying, setForecastPlaying] = useState(false)

  useEffect(() => {
    if(currentForecast)
      setInterval(currentInterval);
  }, [currentForecast]);

  useEffect(() => {
    if (slider.current) {
      $(slider.current)
        .slider()
        .on('change', (e) => {
          e.preventDefault();
          const target = $(e.currentTarget);
          currentInterval=target.slider('getValue');
          if(onChangeInterval)
            onChangeInterval(currentInterval);
            setForecastInterval(currentInterval)
        });
    }
  }, [slider, currentForecast]);

  const playForecast = () => {
    setForecastPlaying(true)
    if(forecastInterval >= 48)
      stepForecast(0)
    else
      stepForecast(forecastInterval)
  }

  const stepForecast = (interval) => {
    $(slider.current).slider("setValue", interval)
    onChangeInterval(interval)
    setForecastInterval(interval)

    interval += 1;
    if(interval < 49)
      playTo.current = setTimeout(() => stepForecast(interval), 350);
    else
      setForecastPlaying(false)
  }

  const pauseForecast = () => {
    setForecastPlaying(false)
    clearTimeout(playTo.current)
  }

  return <div className="information-strip-container">
    {currentForecast?.id ?
      (
        <>
          <ForecastSelector />
          <div className="forecast-navigator">
            <ForecastSourceDisplay />
            <div className="forecast-time-navigator">
              <span style={{ display: 'inline-block', width: '150px' }} >{moment(currentForecast.issuedAt).utc().add(forecastInterval,'hour').format('L')}&nbsp;{moment(currentForecast.issuedAt).utc().add(forecastInterval,'hour').format('HH:mm')}</span>
              <input
                      id={`time-selector`}
                      ref={slider}
                      className="slider layer-opacity"
                      type="text"
                      data-slider-min="0"
                      data-slider-max="48"
                      data-slider-step="1"
                      data-slider-value={forecastInterval}
                      />
              {forecastPlaying ? 
                <i className='fas fa-pause icon-btn mx-3' onClick={pauseForecast} />
                :
                <i className='fas fa-play icon-btn mx-3' onClick={playForecast} />
                
              }
          </div>
        </div>
        </>
      ) :
      (<span>Aucune prévision n'a pu être chargée.</span>)
    }
  </div>
}

export default InformationStrip