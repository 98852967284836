import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import MaplibreGl from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'

import utilUrl from '../../utility/utilUrl'

class CustomControls {
	handleLocationToggle = null;
	onAdd(map) {
		this._container = document.createElement('div')
		return this._container;
	}
	onRemove() {
		this._container.parentNode.removeChild(this._container)
		this._map = undefined
	}
}

const MapMapbox = ({ focus, style, rebuildMap, states, layout, onSelect, forecastLayer, forecastInterval }) => {
	const [locationShow, setLocationShow] = useState(false);
	const [mapLoaded, setMapLoaded] = useState(false);
	const [map, setMap] = useState();
	const [prevErr, setPrevErr] = useState();
	const [prevStates, setPrevStates] = useState({});

	let container = document.createElement('div')
	const loadFeatureStates = () => {
		
		for(const layer_id in states)
		{
			const layer = style.layers.filter(layer => layer.id === layer_id)[0];
			const layerStates = states[layer_id];
			for(const [k, v] of Object.entries(layerStates)){
				let selector = { source: layer["source"], sourceLayer: layer["source-layer"], id: k}
				if (Array.isArray(v)) {
					map.setFeatureState(selector, {"featureResultOverrideId": v[0]})
					map.setFeatureState(selector, {"base_dys_in": v[1]})
					if (v[0]===false){
						map.setFeatureState(selector, {"dys_in": 0})
					}
					else{
						map.setFeatureState(selector, {"dys_in": undefined})
					}
				}
				else
					{map.setFeatureState(selector, {"dys_in": v})}
		}}

		setPrevStates(states)

	}


	useEffect( ()=>{
		if(!mapLoaded || prevStates == states) return;
		
		loadFeatureStates();

	}, [states, mapLoaded])


	const applyLayout = () => {
		for (const l of layout)
		{
			map.setLayoutProperty(l.id, "visibility", l.visible?'visible':'none');
			map.setPaintProperty(l.id, l.geomtype+'-opacity', l.opacity)
		}
	}

	useEffect( ()=>{
		if(!mapLoaded || !forecastLayer) return;

		if(map.getLayer('forecast'))
			map.removeLayer('forecast')
		if(map.getSource('forecast'))
			map.removeSource('forecast')

		let position = (style.layers.filter(l => l['layer-type']=='static')[0]||{id:undefined}).id;
		const tiles = 'http://localhost:8081/tegola/maps/' + forecastLayer + '/{z}/{x}/{y}.pbf';
		map.addLayer({
			id: 'forecast',
			source: { type:'vector', tiles: [tiles] },
			'source-layer': forecastLayer.split('/').slice(-1)[0],
			type: 'fill',
			paint: {
        "fill-color": [
          "match",
          [
            "get",
            "level"
          ],
          0, "#30123b",
          1000, "#4455c4",
          2000, "#4390fe",
          3000, "#1fc9dd",
          4000, "#2aefa1",
          5000, "#7eff55",
          6000, "#c2f234",
          7000, "#f2c93a",
          8000, "#fe8f29",
          9000, "#e94d0d",
          10000, "#bd2002",
          "transparent"
        ],
				"fill-opacity": 0.2
      }
		}, position)

		filterForecastInterval(0);

	}, [forecastLayer, mapLoaded])

	const filterForecastInterval = (interval) => {
		map.setFilter('forecast', ['==', ['get', 'interval'], interval]);
	}

	useEffect( ()=>{
		if(!mapLoaded || !forecastLayer) return;
		filterForecastInterval(forecastInterval);
	}, [forecastInterval, forecastLayer, mapLoaded])


	useEffect( ()=>{
		if(!mapLoaded) return;
		
		applyLayout();

	}, [layout, mapLoaded])


	const handleLocationToggle = (show)=>{
		if (locationShow){
			setLocationShow(false)
		} else {
			setLocationShow(true)
		}
	};

	let hash = window.location.hash;

	const buildMap = ()=>{
		if (!style) return <div/>

		if (map){
			// tear down map
			return;
			// map.remove()
		}

		let prevErr = false;
		window.onerror = (message, source, lineno, colno, error)=>{
			const errString = JSON.stringify(error)
			if (errString === prevErr) return
			
			setPrevErr(errString)
		}

		let newMap = new MaplibreGl.Map({
			attributionControl:false,
			logoPosition:'bottom-right',
			container: container,
			style: style,
			transformRequest: transformRequest,
			hash:true
		});
		newMap.setMinZoom(6);
		// newMap.setMaxZoom(10);

		const Controls = new CustomControls({})
		Controls.handleLocationToggle = handleLocationToggle

		newMap.addControl(Controls)

		newMap.addControl(new MaplibreGl.AttributionControl({
			compact: true
		}))

		const nav = new MaplibreGl.NavigationControl()
		newMap.addControl(nav, 'bottom-right')

		newMap.on('error',(e)=>{
			handleMapError(e.error)
		})

		newMap.on('moveend', ()=>{
			if (window.location.hash) hash = window.location.hash
		})

		newMap.on('load', (e)=>{
			setMapLoaded(true)
		})

		newMap.on('click', (e)=>{
			handleClick(e, newMap)
		})

		setMap(newMap)
	}

	let builtMap = null;
	let clickMarker = null;

	useEffect(()=>{

		if (!style || !style["version"]) return
		if (!builtMap && !mapLoaded){
			buildMap()
			builtMap = rebuildMap
			return
		}
		
		if (!window.location.hash){
			if (window.history.replaceState) {
	    	window.history.replaceState(null, null, hash)
			}
			else {
			  window.location.hash = hash
			}
		}
		reStyleMap()
	}, [style, rebuildMap])
	
	const queryMapFeatures = (map, point)=>{

		const pointProj = map.project(point)
	
		const margin = 5
		const bbox = [
			{x:pointProj.x-margin,y:pointProj.y+margin},
			{x:pointProj.x+margin,y:pointProj.y-margin}
		]
	
		const features = map.queryRenderedFeatures(bbox)
		return features || []
	}

	let prevSelected = [];
	const handleClick = (e, map)=>{
		const point = map.unproject(e.point)
		const featuresDuped = queryMapFeatures(map, point);
		
		// dedupe features
		let found = [], features = []
		featuresDuped.forEach(feature => {
			if(feature.layer.id.endsWith('-sel')) return;
			const key = `${feature.source}~${feature.sourceLayer}~${feature.layer.id}~${feature.id}`
			if (!found.includes(key)){
				found.push(key)
				features.push(feature)
			}
		})

		for (const f of prevSelected){
			map.setFeatureState({ source: f["source"], sourceLayer: f["sourceLayer"], id: f.id}, {"selected": false})
		}
		for (const f of features){
			map.setFeatureState({ source: f["source"], sourceLayer: f["sourceLayer"], id: f.id}, {"selected": true})
		}
		prevSelected = features;
		let selected = {};
		for (const f of  features)
			{
				selected[f.layer["source-layer"]] = selected[f.layer["source-layer"]] || []
				
				let props = f.properties
				props["dys_in"] = f.state["dys_in"];
				props['id'] = f.id;
				props['layerId'] = f.layer.id;
				if (f.state["featureResultOverrideId"]!==undefined) {
					props["base_dys_in"] = f.state["base_dys_in"]
					props["featureResultOverrideId"] = f.state["featureResultOverrideId"]
				}
				if(selected[f.layer["source-layer"]].indexOf(f2 => f2.id === f.id)<=0)
					selected[f.layer["source-layer"]].push(props)	
			}
		for (const l in selected)
			selected[l].sort((a,b)=> a.dys_in - b.dys_in)
		if(onSelect)
			onSelect(selected);
	}

	const handleMapError = (error)=>{
		const errString = JSON.stringify(error)
		if (errString === prevErr) return
		console.error(error)
		console.log(style)
		setPrevErr(errString)
	}

	

	const handleMarkerClose = (e)=>{
		e.stopPropagation()
		if (clickMarker) clickMarker.remove()
		modelMap.actions.clearFocus()
	}


	const reStyleMap = ()=>{
		try {
			map && map.setStyle(style,{diff: true})
		} catch(e){
			handleMapError(e)
		}
	}

	return (
		<React.Fragment>
			<div id="map" ref={el => container = el}></div>
		</React.Fragment>
	)

	const transformRequest = (url, resourceType)=>{
		const {domainHeaders} = props

		const domain = utilUrl.getDomain(url)

		if (domainHeaders && domainHeaders.has(domain)){
			const headers = domainHeaders.getIn([domain]).toJS()

			let sendHeaders = {}
			Object.keys(headers).forEach(key => {
				if (key && key.length > 0){
					sendHeaders[key] = headers[key]
				}
			})

			return {
				url: url,
				headers: sendHeaders,
			}
		}
	}
}

MapMapbox.propTypes = {
	focus:  PropTypes.shape({
		lat: PropTypes.number,
		lng: PropTypes.number,
	}),
	handle: PropTypes.object,
	match: PropTypes.object,
	rebuildMap: PropTypes.number,
	style: PropTypes.object.isRequired,
	states: PropTypes.object,
}

export default MapMapbox