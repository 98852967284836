import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Layer } from '../../types/Layer';
import FieldWithApiErrors from '../ReactHookForms/FieldWithApiErrors';
import I18n from "../../packs/translations"
import { Toast } from '../Toast';
import moment from 'moment';
import prettyBytes from 'pretty-bytes';
import 'bootstrap-iconpicker-latest/dist/js/bootstrap-iconpicker.min.js';
import 'bootstrap-iconpicker-latest/dist/css/bootstrap-iconpicker.min.css';
import useCurrentUser from '../Users/CurrentUser';
import { useDropzone } from 'react-dropzone'

declare global {
  interface Window {
    $?: any;
  }
}

interface LayersFormParams {
  layerId?: string;
  organisations: OrganisationParams[];
  geometryTypes: string[]
}

interface OrganisationParams {
  id: number;
  name: string;
}

interface FormLayer extends Omit<Layer, "id" | "isImported"> {
  _id: number;
  isImported: boolean;
  isChildLayer: boolean;
  faIcon?: string;
  svgIcon?: File;
  newShapefile?: File;
  organisationId?: number;
  geometryType?: string;
  paint?: string;
  originalLayerId?: number
}

const LayersForm = ({ layerId, organisations, geometryTypes }: LayersFormParams) => {
  const currentUser = useCurrentUser();
  const [layer, setLayer] = useState<FormLayer>({
    zIndex: 1,
    opacityByDefault: 1,
    isImported: true,
    isChildLayer: false
  } as FormLayer);
  const [errors, setErrors] = useState<any>({});
  const [layerTypes, setLayerTypes] = useState<{ _id: number, name: string }[]>([]);
  const [layerSources, setLayerSources] = useState<{ _id: number, name: string }[]>([]);
  const [parentLayers, setParentLayers] = useState<{ _id: number, name: string, attributes:{name: string, type: string}[] }[]>([]);
  const [doUpdateData, setDoUpdateData] = useState<Boolean>(!layerId)
  const [iconNames, setIconNames] = useState<string[]>([])
  const [svgPreview, setSvgPreview] = useState<string | null>(null)

  const { register, handleSubmit, control, reset, setValue, getValues, watch, formState: { isSubmitting, errors: formErrors, ...otherFormState } } =
    useForm({
      defaultValues: layer
    });

  const watchImported = watch("isImported");
  const watchShapefile = watch("newShapefile");
  const watchChildLayer = watch("isChildLayer");
  const watchOrganisation = watch("organisationId");
  const watchParent = watch("originalLayerId");

  const {
    acceptedFiles,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: { "image/svg+xml": ['.svg'] } as any,
    multiple: false,
    onDrop: acceptedFiles => {
      if (acceptedFiles.length) {
        const file = acceptedFiles[0] // Only one file accepted.

        if (file.type !== 'image/svg+xml') {
          Toast.error({ title: "Le fichier doit être au format SVG" })
          return
        }

        const reader = new FileReader()

        reader.onload = (e) => {
          // Do whatever you want with the file contents
          const content = reader.result as string
          setValue('svgIcon', file)
          setSvgPreview(resizeSvg(content))
        }
        reader.readAsText(file)
      }
    }
  });
  const {
    acceptedFiles: acceptedShapefiles,
    getRootProps: getShapefileRootProps,
    getInputProps: getShapefileInputProps
  } = useDropzone({
    accept: {
      "application/json": ['.geojson'],
      "application/zip": ['.zip'],
      "application/x-zip-compressed": ['.zip'],
    } as any,
    multiple: false,
    onDrop: acceptedFiles => {
      if (acceptedFiles.length) {
        const file = acceptedFiles[0] // Only one file accepted.
        if (!['application/json', 'application/geo+json', 'application/zip', 'application/x-zip-compressed'].includes(file.type) && !(/\.geojson$/i.test(file.name))) {
          Toast.error({ title: "Le fichier doit être au format zip ou geojson" })
          return
        }
        setValue('newShapefile', file)
      }
    },
  });


  // Go back to main page
  const backToList = () => {
    window.location.href = "/layers";
  };

  /**
   * Handle form submit errors 
   * @param {*} errors
   */
  const handleHookFormsErrors = (data) => {
    const deepCopyErrors = JSON.parse(JSON.stringify(errors));

    Object.keys(data).forEach((key) => {

      if (deepCopyErrors[key]) {
        deepCopyErrors[key].push(data[key]);
      } else {
        deepCopyErrors[key] = [data[key]];
      }

    });
  };

  /**
   * Save Layer to server
   * @param {*} req
   */
  const save = (req: FormLayer) => {
    const formData = new FormData();
    formData.append("layer[is_imported]", (req.isImported || false).toString())
    // formData.append("layer[locale]", req.locale)
    if (req.svgIcon) { formData.append("layer[svg_icon]", req.svgIcon) }
    else if (req.faIcon && req.faIcon !== "empty") { formData.append("layer[fa_icon]", req.faIcon) }

    formData.append("layer[name]", req.name)
    formData.append("layer[organisation_id]", req.organisationId);
    formData.append("layer[geometry_type]", req.geometryType);
    formData.append("layer[layer_source_id]", req.layerSourceId?.toString());
    formData.append("layer[layer_type_id]", req.layerTypeId?.toString());
    if (req.attributions) { formData.append("layer[attributions]", req.attributions); }
    formData.append("layer[str_paint]", JSON.stringify(JSON.parse(req.paint)));
    formData.append("layer[opacity_by_default]", req.opacityByDefault?.toString());
    formData.append("layer[is_visible_by_default]", req.isVisibleByDefault?.toString());
    formData.append("layer[z_index]", req.zIndex?.toString());
    formData.append("layer[projection]", req.projection);
    if (req.newShapefile) { formData.append("layer[shapefile]", req.newShapefile); }

    if (req.originalLayerId) formData.append("layer[original_layer_id]", req.originalLayerId?.toString())
    if (req.groupOptions) formData.append("layer[original_layer_filter]", req.groupOptions?.toString())



    const [url, method] = layerId
      ? [`/layers/${layerId}.json`, "patch"]
      : [`/layers.json`, "post"];


    return axios[method](url, formData, { "Content-Type": "multipart/form-data" })
      .then(({ data }) => {
        setLayer(data);
        setErrors([]);
      })
      .then(() => Toast.success({ title: "Enregistré" }))
      .then(backToList)
      .catch((res) => {
        Toast.error({
          title:
            res.response.status === 422
              ? "Données invalides"
              : res.response.statusText,
        });
        if (res.response.status === 422) {
          setErrors(res.response.data);
        }
      });
  };

  const isNewLayer = () => {
    return typeof (layer?._id) !== "number"
  }

  useEffect(() => {
    if (layerId) {
      axios.get(`/layers/${layerId}.json`).then((response) => {
        setLayer({ _id: response.data.id, ...response.data });
      });
    }
  }, [layerId]);

  useEffect(() => {
    axios.get(`/layer_types.json`).then((response) => {
      setLayerTypes(response.data.map(layerType => {
        return { ...layerType, _id: layerType.id }
      }
      ))
    });
  }, []);

  useEffect(() => {
    axios.get(`/layer_sources.json`).then((response) => {
      setLayerSources(response.data);
    });
  }, []);

  useEffect(() => {
    axios.get(`/layers.json`).then((response) => {
      setParentLayers(response.data.filter(l => l.attributesSchema && l.organisationId == watchOrganisation).map(layer => {
        return { _id: layer.id, id: layer.id, name: layer.name, attributes: layer.attributesSchema.map(attr=>{ return {name: attr.attributeName, type: attr.attributeType}})}
      }));
    });
  }, [watchOrganisation]);


  useEffect(() => {
    axios.get(`/layers/icons.json`).then((response) => {
      setIconNames(response.data);
    });
  }, []);

  useEffect(() => {
    reset(layer)
  }, [layer])

  useEffect(() => {
    window.$('#icon').iconpicker({
      align: 'center', // Only in div tag
      arrowClass: 'btn-danger',
      arrowPrevIconClass: 'fas fa-angle-left',
      arrowNextIconClass: 'fas fa-angle-right',
      cols: 10,
      footer: true,
      header: true,
      icon: 'fas fa-bomb',
      iconset: { iconClass: 'far', iconClassFix: 'fa-', icons: ['', 'eye', 'layer-group'] },
      labelHeader: '{0} of {1} pages',
      labelFooter: '{0} - {1} of {2} icons',
      placement: 'bottom', // Only in button tag
      rows: 5,
      search: true,
      searchText: 'Search',
      selectedClass: 'btn-success',
      unselectedClass: ''
    })
      .on('change', function (e) {
        setValue('faIcon', e.icon.replace('fa-', ''))
      });
  }, [])



  useEffect(() => {
    let availableIcons = []
    let layerIconIsInFontAwesome = false
    let iconName;
    if (layer.icon?.filename) {
      const iconName = layer.icon.filename.replace('.svg', '')
      layerIconIsInFontAwesome = iconNames.includes(iconName)
      if (layerIconIsInFontAwesome) {
        // This icon is in the list of FontAwesome icons, put it first
        availableIcons = [iconName, ...iconNames.filter(icon => icon !== iconName)]
      }
    }

    if (availableIcons.length === 0) {
      // This icon is not in the list of FontAwesome icons, ignore it
      availableIcons = ['', ...iconNames]
    }
    window.$('#icon')
      .iconpicker('setIconset', { iconClass: 'fas', iconClassFix: 'fa-', icons: availableIcons })
    if (layerIconIsInFontAwesome) {
      window.$('#icon').iconpicker('setIcon', `fas fa-${iconName}`)
    }
  }, [layer.icon?.filename, iconNames])


  const resizeSvg = (svg: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(svg, "image/svg+xml");
    const svgElement = doc.documentElement;
    const width = svgElement.getAttribute('width')
    const height = svgElement.getAttribute('height')

    const ratio = parseInt(width) / parseInt(height)
    const newWidth = 60
    const newHeight = newWidth / ratio
    svgElement.setAttribute('width', `${newWidth}`)
    if (!Number.isNaN(newHeight)) {
      svgElement.setAttribute('height', `${newHeight}`)
    }
    return svgElement.outerHTML
  }

  const SvgIconPreview = (svg: string, htmlColor: string) => {
    var parser = new DOMParser();
    var doc = parser.parseFromString(svg, "image/svg+xml");
    // change the svg root stroke color

    // Detect if this is a Font Awesome SVG, ie if there is a comment containing "Font Awesome"
    if (doc.documentElement.innerHTML.includes('Font Awesome')) {
      doc.documentElement.setAttribute("style", `fill:${htmlColor}`)
    } else {
      if (doc.documentElement.getAttribute("style")?.includes('fill')) {
        doc.documentElement.setAttribute("style", `fill:${htmlColor}`)
      } else {
        doc.documentElement.setAttribute("style", `stroke:${htmlColor}`)
      }
      // change the svg path stroke color
      doc.documentElement.querySelectorAll('path').forEach(path => {
        if (path.getAttribute("fill")) {
          path.setAttribute("fill", htmlColor)
        } else {
          path.setAttribute("stroke", htmlColor)
        }
      })
    }

    // Display SVG with the given stroke and fill color
    return <span dangerouslySetInnerHTML={{ __html: doc.documentElement.outerHTML }} />
  }
  var parser = new DOMParser();
  var doc = parser.parseFromString(layer.iconSvgContent, "image/svg+xml");
  doc.documentElement.setAttribute("style", "fill:#9496a1")
  return (
    <div className="row">
      <div className="col-12">
        {layer?.importError &&
          <div className="alert-inpage alert-danger" role="alert">
            L'importation des données semble avoir échoué. Veuillez <a href="#upload-data" data-turbo="false">téléverser de nouvelles données</a>.
          </div>}
        <form onSubmit={handleSubmit(save, handleHookFormsErrors)}>
          <div className="card">
            <div className="card-header">
              Général
            </div>
            <div className="card-body">

              <FieldWithApiErrors
                name="name"
                defaultValue={layer.name}
                label="Nom"
                errors={errors}
                registerCallBack={register}
              />
            {organisations && (
              <div className="form-group row">
                <label className="col-sm-2 col-form-label" htmlFor="organisation">
                  Organisation
                </label>
                <div className="col-sm-10">
                  <select
                      id="organisation"
                      className={`form-control ${errors.organisation ? "is-invalid" : ""
                        }`}
                      defaultValue={layer.organisationId}
                      {...register('organisationId', { required: true })}
                    >
                      {organisations.map((orga) => (
                        <option
                          value={orga.id}
                          key={`orga-${orga.id}`}
                        >
                          {orga.name}
                        </option>
                      ))}
                    </select>
                  {errors.organisation && (
                    <div className="invalid-feedback">{errors.name}</div>
                  )}
                </div>
              </div> 
            )}


              <div className="form-group row">
                <label className="col-sm-2 col-form-label" htmlFor={"faIcon"}>
                  Icone
                </label>
                <div className="col-sm-5">
                  <button id="icon"
                    className="btn btn-secondary"
                    data-iconset="fontawesome5"
                    data-icon="fas fa-map-marker-alt"
                    role="iconpicker"
                    type="button"
                    {...register('faIcon')} />
                </div>

                <div className="col-sm-5">
                  {currentUser.hasRole('keyros') && (
                    // Try with https://www.svgrepo.com/
                    <section className="container">
                      <div {...getRootProps({ className: 'dropzone' })} style={{"maxHeight":"70px","overflow":"hidden","padding":"12px 20px"}}>
                        <input {...getInputProps({
                          accept: 'image/svg+xml',
                          multiple: false,
                        })} />
                        <p>Déposez une icône au format <em>SVG</em> ici ou cliquez pour la sélectionner</p>
                      </div>
                      {svgPreview &&
                        <div className="row">
                          <div className="col-12 text-center p-4">
                            <span dangerouslySetInnerHTML={{ __html: svgPreview }} />
                          </div>
                          <div className="col-12">
                            <table className="table table-sm">
                              <tbody>
                                <tr>
                                  <th>Nom de fichier</th>
                                  <td>{getValues('svgIcon')?.name}</td>
                                </tr>
                                <tr>
                                  <th>Taille</th>
                                  <td>{getValues('svgIcon')?.size && prettyBytes(getValues('svgIcon')?.size)}</td>
                                </tr>
                                <tr>
                                  <th>Exemples</th>
                                  <td>
                                    {["#EFE052", "orange", "red"].map(color => (
                                      <span key={`svg-preview-${color}`} className="p-2">
                                        {SvgIconPreview(svgPreview, color)}
                                      </span>
                                    ))}

                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <button type="button" className="btn btn-danger btn-sm pull-right"
                              onClick={() => {
                                setValue('svgIcon', null)
                                setSvgPreview(null)
                              }}>
                              Supprimer
                            </button>
                          </div>
                        </div>
                      }
                    </section>
                  )}
                </div>
              </div>


              <div className="form-group row">
                <label className="col-sm-2 col-form-label" htmlFor={"layer-type"}>
                  Type de couche
                </label>
                <div className="col-sm-10">
                  <select
                    className={`form-control  ${errors["layerTypeId"] ? "is-invalid" : ""}`}
                    id={"layer-type"}
                    defaultValue={layer?.layerTypeId || ""}
                    required
                    {...register('layerTypeId', { required: true })}
                  >
                    <option value="" disabled>Choisir un type de couche</option>
                    {layerTypes.map((layerType: any) => (
                      <option key={layerType._id} value={`${layerType._id}`}>
                        {I18n.t(`layer_types.${layerType.name}`)}
                      </option>
                    ))}
                  </select>
                  {errors["layerTypeId"] && (
                    <div className="invalid-feedback">{errors["layerTypeId"]}</div>
                  )}
                </div>
              </div>

             

            </div>
          </div>


          <div className="card" id="upload-data">
            <div className="card-header">
              Données
            </div>

            <div className="card-body">
              <div className="form-group row">
                <label className="col-sm-2 form-label pt-2" htmlFor="is_imported">
                  Origine des données
                </label>
                <div className="col-sm-10">
                  {layer._id
                    ? <button type="button" className={`btn btn-lg btn-primary `} disabled>
                      {watchImported ? 'Fichier' : watchChildLayer ? 'Autre couche' : 'Externe'}
                    </button>
                    : <div className="btn-group" role="group" aria-label="Layer type selector">
                      <button type="button" className={`btn btn-lg ${watchImported && !watchChildLayer ? 'btn-primary' : 'btn-outline-primary'}`}
                        onClick={() => {
                          setValue('isImported', true)
                          setValue('isChildLayer', false)
                        }}
                      >Fichier</button>
                      <button type="button" className={`btn btn-lg ${!watchImported && !watchChildLayer ? 'btn-primary' : 'btn-outline-primary'}`}
                        onClick={() => {
                          setValue('isImported', false)
                          setValue('isChildLayer', false)
                        }}
                      >Externe</button>
                      <button type="button" className={`btn btn-lg ${!watchImported && watchChildLayer  ? 'btn-primary' : 'btn-outline-primary'}`}
                        onClick={() => {
                          setValue('isImported', false)
                          setValue('isChildLayer', true)
                        }}
                      >Autre couche</button>
                    </div>}

                  {errors["isImported"] && (
                    <div className="invalid-feedback">{
                      errors["isImported"].length > 1 ?
                        errors["isImported"].map((error: any) => <div key={error}>{error}</div>) :
                        errors["isImported"]
                    }</div>
                  )}



                </div>
              </div>

              {layer?.shapefile && <>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label" htmlFor="shapefile">
                    Données originales
                  </label>
                  <div className="col-sm-10">
                    <table className="table table-sm">
                      <tbody>
                        <tr>
                          <th>Nom</th>
                          <td><a href={layer.shapefileUrl}>
                            {layer.shapefile.filename}
                          </a></td>
                        </tr>
                        <tr>
                          <th>Taille</th>
                          <td>{prettyBytes(layer.shapefile.byteSize)}</td>
                        </tr>
                        <tr>
                          <th>Date</th>
                          <td>{moment(layer.shapefile.createdAt).utc().format('L HH:mm')}</td>
                        </tr>
                        <tr>
                          <th></th>
                          <td>
                            <button type="button" className="btn btn-info" onClick={() => setDoUpdateData(!doUpdateData)}>
                              Mettre à jour
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                  </div>
                </div>
              </>}
              {
                watchImported
                  ? doUpdateData && <>
                    <div className="form-group row">
                      <div className="col-sm-2 col-form-label">
                        Fichier
                      </div>
                      <div className="col-sm-10">
                        <section className="container">
                          <div {...getShapefileRootProps({ className: 'dropzone' })}>
                            <input {...getShapefileInputProps({
                              multiple: false,
                            })} />
                            {
                              watchShapefile
                              ? <p>{watchShapefile.name} ({prettyBytes(watchShapefile.size)})</p>
                              : <p>Déposez un fichier geojson, Shapefile ou zip</p>
                            }
                          </div>
                        </section>
                      </div>
                    </div>

                    <FieldWithApiErrors
                      name="projection"
                      defaultValue={layer.projection}
                      label="Projection"
                      placeholder='Déduit automatiquement depuis le fichier shape'
                      errors={errors}
                      registerCallBack={register}
                      required={false}
                    />
                  </>
                  : watchChildLayer ? 
                  <>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label" htmlFor={"layer-source"}>
                        Couche parente
                      </label>
                      <div className="col-sm-10">
                        <select
                          className={`form-control  ${errors["originalLayerId"] ? "is-invalid" : ""}`}
                          id={"original-layer"}
                          value={watchParent && watchParent?.toString()}
                          {...register('originalLayerId', { required: true })}
                        >
                          <option value="">Choisir la couche parente</option>
                          {parentLayers.map((layer: any) => (
                            <option key={`original-layer-${layer.id}`} value={layer.id} >
                              {layer.name}
                            </option>
                          ))}
                        </select>
                        {errors["originalLayerId"] && (
                          <div className="invalid-feedback">{errors["originalLayerId"]}</div>
                        )}
                      </div>
                    </div>
                    {watchParent && 
                    <div className="form-group row">
                    <label className="col-sm-2 col-form-label" htmlFor={"layers"}>
                      Filtre
                    </label>
                    <div className="col-sm-10">
                      <input
                        className={`form-control  ${errors["groupOptions"] ? "is-invalid" : ""}`}
                        id={"group-options"}
                        defaultValue={layer?.sourceUrl}
                        {...register('groupOptions', { required: true })}
                      />
                      {errors["groupOptions"] && (
                        <div className="invalid-feedback">{errors["groupOptions"]}</div>
                      )}
                    </div>
                  </div>
                    // <div className="form-group row">
                    //   <label className="col-sm-2 col-form-label" htmlFor={"layer-source"}>
                    //     Attribut filtre
                    //   </label>
                    //   <div className="col-sm-10">
                    //     <select
                    //       className={`form-control  ${errors["originalLayerId"] ? "is-invalid" : ""}`}
                    //       id={"attribute-filter-key"}
                    //       // defaultValue={layer?.originalLayerId}
                    //       {...register('attributeFilterKey', { required: true })}
                    //     >
                    //       <option value="">Choisir l'attribut servant de filtre</option>
                    //       {
                    //       parentLayers.find(l=>l.id==watchParent).attributes.map((att) => (
                    //         <option key={`attribute-filter-key-${att.name}`} value={layer.name}>
                    //           {att.name}
                    //         </option>
                    //       ))}
                    //     </select>
                    //     {errors["originalLayerId"] && (
                    //       <div className="invalid-feedback">{errors["originalLayerId"]}</div>
                    //     )}
                    //   </div>
                    // </div>
                    
                    }
                  </>
                  :<>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label" htmlFor={"layer-source"}>
                        Type de source
                      </label>
                      <div className="col-sm-10">
                        <select
                          className={`form-control  ${errors["layerSourceId"] ? "is-invalid" : ""}`}
                          id={"layer-source"}
                          defaultValue={layer?.layerSourceId}
                          {...register('layerSourceId', { required: true })}
                        >
                          <option value="">Choisir une source pour la couche</option>
                          {layerSources.map((layerSource: any) => (
                            <option key={`layer-source-${layerSource.name}`} value={layerSource.id}>
                              {I18n.t(`layer_sources.${layerSource.name}`)}
                            </option>
                          ))}
                        </select>
                        {errors["layerSourceId"] && (
                          <div className="invalid-feedback">{errors["layerSourceId"]}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label" htmlFor={"layer-source"}>
                        URL
                      </label>
                      <div className="col-sm-10">
                        <input
                          className={`form-control  ${errors["sourceUrl"] ? "is-invalid" : ""}`}
                          id={"sourceUrl"}
                          defaultValue={layer?.sourceUrl}
                          {...register('sourceUrl', { required: true })}
                        />
                        {errors["sourceUrl"] && (
                          <div className="invalid-feedback">{errors["sourceUrl"]}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label" htmlFor={"layers"}>
                        Nom de la couche
                      </label>
                      <div className="col-sm-10">
                        <input
                          className={`form-control  ${errors["layers"] ? "is-invalid" : ""}`}
                          id={"layers"}
                          defaultValue={layer?.sourceUrl}
                          {...register('layers', { required: true })}
                        />
                        {errors["layers"] && (
                          <div className="invalid-feedback">{errors["layers"]}</div>
                        )}
                      </div>
                    </div>
                  </>
              }

              {layer?.shapefile && layer._id && <div className="form-group row">
                <label className="col-sm-2 col-form-label" htmlFor="shapefile">
                  Données transformées
                </label>
                <div className="col-sm-10">
                  <a className="btn btn-outline-secondary mr-4" href="export.geojson">
                    <i className="fas fa-download mr-2"></i>
                    GeoJSON
                  </a>
                  <a className="btn btn-outline-secondary" href="export.shp">
                    <i className="fas fa-download mr-2"></i>
                    Shapefile
                  </a>
                </div>
              </div>}
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              Affichage
            </div>
            <div className="card-body">

            {geometryTypes && (
            <>
              <div className="form-group row">
                <label className="col-sm-2 col-form-label" htmlFor="geometry_type">
                  Type de geometrie
                </label>
                <div className="col-sm-10">
                  <select
                      id="geometry_type"
                      className={`form-control ${errors.geometry_type ? "is-invalid" : ""
                        }`}
                      defaultValue={layer.geometryType}
                      {...register('geometryType', { required: true })}
                    >
                      {geometryTypes.map((geom) => (
                        <option
                          value={geom}
                          key={`geom-${geom}`}
                        >
                          {geom}
                        </option>
                      ))}
                    </select>
                  {errors.geometry_type && (
                    <div className="invalid-feedback">{errors.name}</div>
                  )}
                </div>
              </div> 
              <div className="form-group row">
                <label className="col-sm-2 col-form-label" htmlFor="paint">
                  Style
                </label>
                <div className="col-sm-10">
                  <textarea id="paint" defaultValue={layer.paint ? JSON.stringify(JSON.parse(layer.paint)) : "{}"}
                      className={`form-control ${errors.paint ? "is-invalid" : ""
                    }`}
                    {...register('paint', { required: true })}
                  >
                  </textarea>
                  {errors.name && (
                    <div className="invalid-feedback">{errors.name}</div>
                  )}
                </div>
              </div>
            </>
          )}

            <FieldWithApiErrors
                type='checkbox'
                name="isVisibleByDefault"
                defaultValue={layer.isVisibleByDefault?"checked":"unchecked"}
                label="Visible par défault"
                errors={errors}
                required={false}
                registerCallBack={register}
              />


            <FieldWithApiErrors
                type='number'
                name="opacityByDefault"
                defaultValue={layer.opacityByDefault}
                label="Opacité par défaut"
                min={0}
                max={1}
                step={0.1}
                errors={errors}
                registerCallBack={register}
              />


              <FieldWithApiErrors
                type='number'
                name="zIndex"
                defaultValue={layer.zIndex}
                label="Priorité d'affichage"
                errors={errors}
                registerCallBack={register}
                min={0}
              />

              <FieldWithApiErrors
                type='textarea'
                name="attributions"
                defaultValue={layer.attributions}
                label="Crédits"
                errors={errors}
                registerCallBack={register}
                required={false}
              />

              <div className="col-8 p-4 text-right">

              </div>

            </div>
            <button type="submit" className="btn btn-success" disabled={isSubmitting}>
              {" "}
              Enregistrer
            </button>
          </div>
        </form>
      </div>

    </div>
  );
}

export default LayersForm;