import axios from 'axios'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ForecastContext, AlternativeBadge } from '../../../Forecasts';
import { Toast } from '../../../Toast';
import moment from 'moment';
import '../../../../types/AxiosConfig';

const ForecastAlternativeSelector = ({ setShowForecastSelector }) => {
  const [alternativeBulletins, setAlternativeBulletins] = useState([])
  const { currentForecast, loadForecast } = useContext(ForecastContext)
  const [forecastAlternativeBulletin, setForecastAlternativeBulletin] = useState(currentForecast)
  const [lastForecastDates, setLastForecastDates] = useState([]);
  const controller = new AbortController();
  useEffect(() => {
    if (forecastAlternativeBulletin?.id) {
      axios.get(`/forecast_bulletins/${forecastAlternativeBulletin.id}/alternatives.json`,
        { signal: controller.signal })
        .then(({ data }) => setAlternativeBulletins(data))
    }

    return () => {
      // controller.abort();
    }
  }, [forecastAlternativeBulletin])

  useEffect(() => {
    axios.get(`/forecast_bulletins/dates.json`,
      { signal: controller.signal })
      .then(({ data }) => setLastForecastDates(data))
      .catch((error) => {
        Toast.error({ text: error.message, title: 'Error' })
        console.error(error)
      });
  }, [])

  const changeForecastDate = useCallback((e) => {
    const date = e.target.value
    const id = lastForecastDates.find((f) => f.issuedAt === date)?.id
    if (id) {
      setForecastAlternativeBulletin({ id })
    } else {
      Toast.error({ text: 'No forecast found for this date', title: 'Error' })
    }
  }, [setForecastAlternativeBulletin, lastForecastDates])

  const changeForecastAlternative = useCallback((selectedForecastBulletinId, e) => {
    e.nativeEvent.preventDefault()
    e.stopPropagation()
    
    return loadForecast(selectedForecastBulletinId)
            .then(() => setShowForecastSelector(false))
  }, [forecastAlternativeBulletin, loadForecast, setShowForecastSelector])

  const forecastDateSelector = useMemo(() => (
    <select className="form-control" name='forecastDate' onChange={changeForecastDate} value={forecastAlternativeBulletin?.issuedAt}>
      {lastForecastDates?.map(forecastDate => (
        <option value={forecastDate.issuedAt} key={`forecast-date-${forecastDate.id}`}>
          {moment(forecastDate.issuedAt).utc().format('L HH:mm')}
        </option>
      ))}
    </select>
  ), [lastForecastDates, changeForecastDate, forecastAlternativeBulletin])

  const forecastAlternativeSelector = useMemo(() => (
    alternativeBulletins.map(bulletin => (
      <div key={`alternative-bulletin-${bulletin.id}`}>
        <a href='#' onClick={(e) => changeForecastAlternative(bulletin.id, e)}>
          <AlternativeBadge bulletin={bulletin} size='lg' emptyValue="Standard" style={{ width: 'fit-content' }}>
            {bulletin.evaluationsCompleted
              ? null
              : <span className='text-warning ml-2'>
                <i className="fas fa-exclamation-triangle "
                  title={`${bulletin.missingEvaluations.length} évaluations non effectuées`}>
                </i>
              </span>}
          </AlternativeBadge>
        </a>
      </div>
    ))
  ), [alternativeBulletins, changeForecastAlternative])

  const currentForecastAlternativeDisplay = useMemo(() => (
    <AlternativeBadge bulletin={currentForecast} size='lg' emptyValue="Standard" colorStyle="primary" showDate />
  ), [currentForecast])

  return <>
    <div className="row">
      <div className="col-3">Date</div>
      <div className="col-9">
        {forecastDateSelector}
      </div>
    </div>
    <div className="row">
      <div className="col-3">Actuel</div>
      <div className="col-9">{currentForecastAlternativeDisplay}</div>
    </div>
    <div className="row">
      <div className="col-3">Sélectionner:</div>
      <div className="col-9">{forecastAlternativeSelector}</div>
    </div>
  </>
}

export default ForecastAlternativeSelector