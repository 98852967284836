import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import LayerControlList from './LayerControl/LayerControlList';

export const LAYER_TYPES = [
  { name: 'background', label: 'Fonds de carte', icon: <i className="fas fa-globe menu-icon" /> },
  { name: 'dynamic', label: 'Données dynamiques', icon: <i className="fas fa-hourglass-start menu-icon" /> },
  { name: 'static', label: 'Données statiques', icon: <i className="fas fa-image menu-icon" /> },
  //{ name: 'provider', label: 'Données référentes', icon: <i className="fas fa-ruler-vertical menu-icon" /> },
  { name: 'scenario', label: 'Couches scénarios', icon: <i className="fas fa-film menu-icon" /> },

];

const LayerMenu = ({ layers, layerAggregations, style, onChange }) => {
  const layerTypes = LAYER_TYPES.map((layerType) => ({
    ...layerType,
    layers: layers.concat(layerAggregations).filter((layer) => layer['layerType'] === layerType.name)
  })
  );

  return (
    <>
    {layerTypes
        .filter(({ layers }) => layers.length)
        .map((layerType, index) => (
          <LayerControlList key={index} {...layerType} style={style} onChange={onChange} />
        ))}
    </>
  );
};

LayerMenu.propTypes = {
  layers: PropTypes.arrayOf(PropTypes.shape({
    layerType: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  })).isRequired,
};

export default LayerMenu;
