import React, { useContext } from "react";
import { ForecastContext, AlternativeBadge } from '../Forecasts';
import Modal from "../Modal";
import moment from "moment";
import 'moment/locale/fr';
import ForecastAlternativeSelector from "../Forecasts/Bulletins/Alternatives/Selector";

const ForecastSelector = () => {
  const { currentForecast, loadForecast, isObsolete: isBulletinObsolete } = useContext(ForecastContext);
  const [showForecastSelector, setShowForecastSelector] = React.useState(false);


  // Display the forecast date selector
  const displayForecastBulletinSelector = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setShowForecastSelector(true)
    return false;
  }

  // Display of the forecast bulletin selector, including scenarios
  const forecastSelector = <Modal  animation={false} size="lg" title="Prévisions alternatives" show={showForecastSelector} onHide={() => setShowForecastSelector(false)}>
    <ForecastAlternativeSelector setShowForecastSelector={setShowForecastSelector} />
  </Modal>

  return (
    <span>
    {forecastSelector}
    {currentForecast.evaluationsCompleted
      ? null
      : <span className='text-warning mr-1'>
        <i className="fas fa-exclamation-triangle "
          title={`${currentForecast.layerFeatureEvaluators.length - currentForecast.activeResultRegisterings.length} évaluations non effectuées`}>
        </i>

      </span>}
    Prévisions du
    <a href="#" className="key-info mx-1 forecast-date-selector" onClick={displayForecastBulletinSelector}>
      {moment(currentForecast.issuedAt).utc().format('L')}
    </a>

    à
    <span className="key-info mx-1">
      {moment(currentForecast.issuedAt).utc().format('HH:mm')}
    </span>
    <a href="#" onClick={displayForecastBulletinSelector}>
      <AlternativeBadge bulletin={currentForecast} style={{ verticalAlign: 'text-bottom' }} />
    </a>
    {isBulletinObsolete && <i className='fas fa-rotate-right ml-2 text-warning clickable' 
    onClick={() => loadForecast(currentForecast.id)}
    title="Nouvelles données disponibles\nCliquer pour recharger les données"></i>}
    </span>
  );
};

export default ForecastSelector;