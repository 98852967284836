import React, { useState } from "react"
import LayerControl from "./SimpleLayerControl";
import GroupedLayerControl from "./GroupedLayerControl";


const LayerControlList = ({ name, label, icon, layers, onChange }) => {
  const [show, setShow] = useState(false);

  const toggleShow = e => {
    e.preventDefault();
    const newValue = !show;
    setShow(newValue);
  }

  return (
    <li key={`layer-group-${name}`} className={`menu-item-has-children ${show && "show"}`}>
      <a href="#" onClick={e => toggleShow(e)}  >
        {icon}
        {' '}
        {label}
      </a>
      <ul className="sub-menu children">
        {layers.map((layer) => {
          return (
            layer.groupOptions ?
              <GroupedLayerControl show={show} key={`layer-control-${layer.id}`} layer={layer} onLayoutChange={onChange} /> :
              <LayerControl show={show} key={`layer-control-${layer.id}`} layer={layer} onLayoutChange={onChange} />
          )
        })}
      </ul>
    </li>
  )
}

export default LayerControlList;