import React, { useRef, useEffect, useState, useContext, useMemo } from "react";
import $ from "jquery";
import "bootstrap-slider";
import { getMapLayer } from "../LayerStore";
import { layerIconHTML } from "../Layers";


const LayerControl = ({ layer, show, onLayoutChange }) => {
  const slider = useRef();
  // const map = useContext(MapContext);
  const [visible, setVisible] = useState(layer.visible);
  const [opacity, setOpacity] = useState(layer.opacity);


  // répercuter le changement de visibilité dans la carte
  useEffect(() => {
    localStorage.setItem("layerLayout"+layer.id, JSON.stringify({"visible":visible, "opacity":opacity}))
    if(onLayoutChange)
      onLayoutChange();
  }, [visible, opacity]);

  // Initialisation du slider
  useEffect(() => {
    if (slider.current) {
      $(slider.current)
        .slider()
        .on('change', (e) => {
          e.preventDefault();
          const target = $(e.currentTarget);
          layer.opacity=target.slider('getValue');
          setOpacity(layer.opacity);
        });
    }
  }, [slider]);

  const toggleVisibility = (e) => {
    e.preventDefault();
    layer.visible=!visible;
    setVisible(layer.visible);
  };

  const layerIcon = useMemo(() => layerIconHTML(layer, visible), [layer.iconSvgContent, visible]);

  return (
    <div style={{ display: show ? 'block' : 'none' }}>
      <div className="row mr-0">
        <div className="col-9 menu-icon">
          <a href="#" onClick={toggleVisibility}>
            <span style={{ color: visible ? "#FFFFFF" : "#444444" }}>
              {layerIcon}
              {layer.name}
            </span>
          </a>
        </div>

        <div className="col-3">
          <input
            id={`layer-${layer.uuid}-slider`}
            ref={slider}
            style={{ width: "100%", marginLeft: "50px" }}
            className="slider layer-opacity"
            type="text"
            data-layer-id={layer.uuid}
            data-slider-min="0"
            data-slider-max="1"
            data-slider-step="0.1"
            data-slider-value={opacity || 1}
          />
        </div>
      </div>
    </div>
  );
};

export default LayerControl;
