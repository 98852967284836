import React, { useContext, useEffect, useMemo, useState } from 'react';
import { sortedAttributes } from '.';
import SimpleBar from 'simplebar-react';
import { forecastTimeIntervals, forecastTimePill } from '../legends/colorScale';
import { ForecastContext } from '../Forecasts';
import { Toast } from '../Toast';
import LayerConfigs from '../ol/LayersContext';
import axios from 'axios';
import I18n from "../../packs/translations"
import useCurrentUser from '../Users/CurrentUser';

const FeatureTable = ({ feature, names }) => {
  const { currentForecast, loadForecast } = useContext(ForecastContext)
  const [currentFeature, setCurrentFeature] = React.useState(feature);
  const layerConfigs = useContext(LayerConfigs);
  I18n.t('components.feature_info.sidebar.no_attributes')

  // differenciate if delay is either null or 0
  const dysfunctionsIn = currentFeature["dys_in"];
  const currentUser = useCurrentUser()

  const interval = forecastTimeIntervals({ currentForecast, delay: dysfunctionsIn })

  const isDysfunctional = (delay) => delay === 0

  const featureLayer = useMemo(() => {
    return layerConfigs.layers.find((l) => l.id === currentFeature.layerId)
  }, [currentFeature.layerId, layerConfigs.layers])

  useEffect(() => {
    // const { ogcFid } = feature.id;
    // axios.get(`/layers/${featureLayer.id}/features_by_ids.json`,
    //   { params: { featureIds: [ogcFid], forecastBulletinId: currentForecast.id } })
    //   .then(({ data: featuresData }) => {
    //     const updatedFeature = featuresData[0];
    //     setCurrentFeature({ ...updatedFeature, layerId: featureLayer.id });

    //   })
  }, [currentForecast.id])

  const changeFeatureStatus = (e) => {
    e.stopPropagation()
    
    const { layerId, id, dys_in } = currentFeature
    const isFunctional = isDysfunctional(dys_in)
    const url = `/layers/${layerId}/features/${id}/status`
    axios.put(url, { forecast_bulletin_id: currentForecast.id, is_functional: isFunctional })
      .then(() => {
        Toast.success({ title: 'Mise à jour réussie', text: `Statut changé pour ${isFunctional ? 'functionel' : 'dysfunctionel'}` })
        currentFeature["base_dys_in"] = currentFeature["dys_in"];
        currentFeature["dys_in"] = isFunctional ? undefined : 0;
        currentFeature["featureResultOverrideId"] = true;
        setCurrentFeature({...currentFeature})

        return loadForecast(currentForecast.id)
      })
      .catch((error) => {
        console.error(error)
        Toast.error({ title: 'Impossible de mettre à jour le statut', text: error })
      })
    return false
  }

  const removeOverride = (e) => {
    e.stopPropagation()
    const { layerId, id } = currentFeature
    const isFunctional = isDysfunctional(currentFeature["base_dys_in"])
    const url = `/layers/${layerId}/features/${id}/status`
    axios.delete(url, { data: { forecast_bulletin_id: currentForecast.id } })
      .then(() => {
        Toast.success({ title: 'Mise à jour réussie', text: `Statut changé pour fonctionel` })
        currentFeature["dys_in"] = currentFeature["base_dys_in"];
        delete currentFeature["featureResultOverrideId"]
        delete currentFeature["base_dys_in"]
        setCurrentFeature({...currentFeature})

        return loadForecast(currentForecast.id)
      })
      .catch((error) => {
        Toast.error({ title: 'Impossible de mettre à jour le statut', text: error })
      })
    return false
  }

  const attributes = Object.entries(feature);

  const translate = (name) => {
    const translation = {
      "d0": "Aujourd'hui",
      "d1": "Demain",
      "d2": "Après-demain",
      "site_baign": "Site de baignade",
      "true": "Ouvert",
      "false": "Fermé",
      "nature": "Catégorie",
      "usage1": "Nature",
      "nb_logts": "Logement(s)",
      "nb_etages": "Étage(s)",
      "toponyme": "Désignation",
      "commune": "Commune",
      "quartier": "Quartier",
      "nat_detail": "Nature",
      ...names
    }
    return translation[name.toString()] || name;
  }

  const excluded = ["dys_in", "layerId", "img", "hauteur", "largeur"]

  let img = attributes?.filter(name_value=> name_value[0]=="img")
  if (img.length)
    img = img[0][1];

  return !attributes?.length
    ? <div className="alert-inpage alert-warning">
      {I18n.t('components.feature_info.sidebar.no_attributes')}
      {currentUser.isAdmin && <>
        <br />
        <a href={`/layers/${currentFeature.layerId}/feature_public_displays`} target='_blank' rel='noopener noreferrer'>
          {I18n.t('components.feature_info.sidebar.setup_public_attributes')}
        </a>
      </>}
    </div>
    : <table className="table table-sm table-striped table-dark">
      <tbody>
        {img && <tr><td colSpan="2"><img src={img} /></td></tr>}
        {currentFeature.properties?.dynamicProperties.length !== 0 && <tr>
          <th>Dysfonctionne</th>
          <td>
            {forecastTimePill(interval)}
            {currentFeature.featureResultOverrideId!==undefined
              ? <>
                <i className="fas fa-star align-top" style={{ fontSize: '10px', color: '#FFFF49' }} title="Statut surchargé"></i>
                <span onClick={removeOverride} className="icon-btn pull-right mx-1" title="Changer le statut">
                  <i className="fas fa-ban"></i>
                </span>
              </>
              : <span className="icon-btn pull-right mx-1" onClick={changeFeatureStatus}>{
                isDysfunctional(currentFeature.dys_in)
                  ? <i className="fas fa-check text-success" title='Marquer comme fonctionnel'></i>
                  : <i className="fas fa-times text-danger" title='Marquer comme dysfonctionnel'></i>
              }</span>
            }

          </td>
        </tr>
        }
        {attributes.filter(name_value=> excluded.indexOf(name_value[0])==-1)
          .map(( name_value) => (
            <tr key={`${name_value[0]}`}>
              <th>{translate(name_value[0])}</th>
              <td>
                <span dangerouslySetInnerHTML={{ __html: name_value[1] }} />
              </td>
            </tr>
          ))}
      </tbody>
    </table>
};

const PopupFeatureInfo = ({ content, layers }) => {
  const [popupContents, setPopupContents] = useState(content)
  const deSelectAll = () => {
    setPopupContents({})
  }

  useEffect(()=>{
    setPopupContents(content);
  }, [content])
  
  return (
  <div id="sidebar-feature-info" className={Object.values(popupContents).length && Object.values(popupContents)[0].length ? 'visible' : undefined}>
    <i className='fas fa-times icon-btn close-btn' onClick={deSelectAll}></i> 
    <SimpleBar style={{ maxHeight: '100vh' }}>
      {Object.entries(popupContents)
        .map((layer) => {
          const layerSpec = layer[0]
          const layerName = layers.find(l=>l.spec===layerSpec)?.name
          const features = layer[1];
          return (
          <div
            key={`features-${layerSpec}`}
            className="card bg-dark text-white"
          >
            <div className="card-header">{layerName}</div>
            <div
              className="card-body table-responsive"
              data-simplebar="init"
            >
              {features.map((feature) => {
                const names = layers.filter(l=>l.id===feature.layerId)[0].publicNames
                return (
                <FeatureTable key={`FeatureTableFeature-${feature?.id}`}
                  feature={feature} names={names}
                />
              )})}
            </div>
          </div>
        )}
        )}
    </SimpleBar>
  </div>
)};

export default PopupFeatureInfo;
